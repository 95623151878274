<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card>
        <div class="pa-3">
          <div class="d-flex align-center justify-space-between">
            <div class="text-h6">{{ type === TYPE_CREATE ? 'Create domain' : 'Update domain' }}</div>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
        <div class="d-flex pa-3">
          <div class="ma-2" style="width: 330px">
            <div>
              <v-img :src="imageSource" style="text-align: center;" width="100%"> </v-img>
            </div>
            <v-file-input
              v-model="form.image"
              label="Upload image (only .webp)"
              prepend-icon="mdi-camera"
              accept="image/*"
            ></v-file-input>
          </div>
          <div class="ml-2" style="flex: 1">
            <v-text-field
              v-model="form.name"
              :rules="[rules.feildRequired]"
              autofocus
              dense
              label="Name"
              outlined
              placeholder="name"
            ></v-text-field>
            <div class="d-flex">
              <v-text-field
                v-model="form.code"
                :rules="[rules.feildRequired]"
                dense
                label="Code"
                outlined
                placeholder="code"
              ></v-text-field>
              <v-text-field
                v-model="form.icon"
                :rules="[rules.feildRequired]"
                dense
                label="Icon"
                outlined
                placeholder="icon"
              >
                <template v-slot:prepend-inner>
                  <v-icon>{{ form.icon }}</v-icon>
                </template>
              </v-text-field>
            </div>
            <v-textarea
              v-model="form.description"
              dense
              label="Description"
              outlined
              placeholder="description"
            ></v-textarea>
          </div>
        </div>
        <v-card-actions>
          <v-layout>
            <v-spacer />
            <v-btn :disabled="loading" class="mx-2" color="secondary" rounded type="reset" @click="dialog = false">
              Close
            </v-btn>
            <v-btn :loading="loading" color="primary" rounded type="submit" @click="submitData">
              Submit
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import AvatarImg from '@/assets/images/user/avatar.png'
import AvatarBgImg from '@/assets/images/user/background-avatar.png'
import { createDomain, updateDomain } from '@/api/menu-api'
import config from '@/config'

const TYPE_CREATE = 'create'
const TYPE_UPDATE = 'update'
export default {
  props: {},
  data: () => ({
    TYPE_CREATE,
    TYPE_UPDATE,
    dialog: false,
    loading: false,
    valid: false,
    name: undefined,
    rules: {
      feildRequired: value => !!value || 'Feild is required',
    },
    type: TYPE_CREATE,
    form: {},
    AvatarImg,
    AvatarBgImg,
  }),
  computed: {
    imageSource() {
      return this.form.image ? URL.createObjectURL(this.form.image) : `${config.backend_pub}${this.form.image_path}`
    },
  },
  methods: {
    showCreate() {
      this.type = TYPE_CREATE
      this.form = {}
      this.dialog = true
      if (this.$refs.formData) {
        this.$refs.formData.resetValidation()
      }
    },
    showEdit(data) {
      this.type = TYPE_UPDATE
      this.form = { ...data }
      this.dialog = true
    },
    async submitData() {
      try {
        this.$refs.formData.validate()
        if (!this.valid) return
        this.loading = true
        if (this.type === TYPE_CREATE) {
          const formData = new FormData()
          if (this.form.image) {
            formData.append('image_path', this.form.image)
          }
          Object.keys(this.form).forEach(key => {
            if (key !== 'image_path' && key !== 'image') {
              formData.append(key, this.form[key])
            }
          })
          await createDomain(formData)
        }
        if (this.type === TYPE_UPDATE) {
          const formData = new FormData()
          if (this.form.image) {
            formData.append('image_path', this.form.image)
          }
          Object.keys(this.form).forEach(key => {
            if (key !== 'image_path' && key !== 'image') {
              formData.append(key, this.form[key])
            }
          })
          await updateDomain({ id: this.form.id, data: formData })
        }
        this.dialog = false
        this.$parent.getListDomains()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
